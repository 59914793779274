import * as React from 'react';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import {ProtectedComponent} from "../components/protected-component";
import AppBarComponent from "../components/app-bar-component";
import {useAuth} from "../../sign-in/providers/auth-provider";

const defaultTheme = createTheme();

export default function HomePage() {
    const { jwttoken  } = useAuth();

    return (
        <ProtectedComponent>
            <ThemeProvider theme={defaultTheme}>
                <Box sx={{display: 'flex'}}>
                    <CssBaseline/>
                    {jwttoken  ? <AppBarComponent/> : null}
                    {/*<AppBarComponent/>*/}
                </Box>
            </ThemeProvider>
        </ProtectedComponent>

    );
}
