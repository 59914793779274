import Typography from "@mui/material/Typography";
import {Link} from "@mui/material";
import * as React from "react";

export function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="http://digitalcity.com.ua/">
        Digital Community
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}