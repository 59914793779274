import React, {useState} from "react";
import {DndContext, DragEndEvent} from "@dnd-kit/core";
import {
    SimpleTreeItemWrapper,
    SortableTree,
    TreeItemComponentProps,
    TreeItems,
} from "dnd-kit-sortable-tree";
import Box from "@mui/material/Box";
import {ITreeItem} from "../../../assets/type";
import Button from "@mui/material/Button";
import {ItemResponseDto} from "../../../dto/item-response.dto";
import {httpClient} from "../../../util/axios";
import {ServerResponseDto} from "../../../dto/server-response.dto";
import toast from "react-hot-toast";


interface BotTreeProps {
    items: TreeItems<ITreeItem>;
    setItems: React.Dispatch<React.SetStateAction<TreeItems<ITreeItem>>>;
    selectClick: (itemId: string) => void;
    bot: ItemResponseDto;
    setBot: (bot: ItemResponseDto) => void;
}

export const BotTree: React.FC<BotTreeProps> = ({items, setItems, selectClick, bot, setBot}) => {

    const saveChanges = async () => {
        try {
            const response = await httpClient.post<ServerResponseDto<ItemResponseDto>>(`/bot/save`, bot);
            if (response.data.msg) {
                toast.error(response.data.msg);
            } else {
                setBot(response.data.data);
                toast.success('Дані успішео збережено');
            }
        } catch (error) {
            toast.error('Помилка при збереженні!');
            console.error(error);
        }
    }

    const TreeItem = React.forwardRef<
        HTMLDivElement,
        TreeItemComponentProps<ITreeItem>
    >((props, ref) => {
        const {item, depth} = props;

        return (
            <SimpleTreeItemWrapper
                {...props}
                ref={ref}
                disableCollapseOnItemClick
                showDragHandle={false}
                // style={{
                //   fontWeight: depth === 2 ? "bold" : "normal",
                // }}
            >
                <div className="tree-item" onClick={() => selectClick(item.id)}>
                    <div
                        className="tree-item__left"
                        style={{
                            color: depth === 2 ? "blue" : "black",
                        }}
                    >
                        {item.name}
                    </div>
                </div>
            </SimpleTreeItemWrapper>
        );
    });

    const handleItemsChanged = (newItems: TreeItems<ITreeItem>) => {
        const updateTree = (items: TreeItems<ITreeItem>, level: number = 1): TreeItems<ITreeItem> => {
            return items.map((item, index) => {
                const updatedItem = {
                    ...item,
                    level: level,
                    order: index + 1,
                };

                if (updatedItem.children && updatedItem.children.length > 0) {
                    updatedItem.children = updateTree(updatedItem.children, level + 1);
                }

                return updatedItem;
            });
        };

        const updatedItems = updateTree(newItems);

        function transformMenuItem(item: any): ItemResponseDto {
            const findItem = (item: ItemResponseDto, selectId: string): ItemResponseDto | null => {
                if (item.id === selectId) return item;
                for (const child of item.children) {
                    const found = findItem(child, selectId);
                    if (found) return found;
                }
                return null;
            };

            const foundItem: ItemResponseDto | null = findItem(bot, item.id);

            if (!foundItem) {
                console.warn(`Item with id ${item.id} not found.`);
                return {
                    id: item.id,
                    subitem: false,
                    name: item.name,
                    description: "Default description",
                    order: item.order,
                    canReply: false,
                    imageUrl: item.imageUrl,
                    children: item.children ? item.children.map((child: any) => transformMenuItem(child)) : []
                };
            }

            return {
                id: item.id,
                subitem: foundItem.subitem,
                name: item.name,
                description: foundItem.description,
                order: item.order,
                canReply: foundItem.canReply,
                imageUrl: item.imageUrl,
                children: item.children ? item.children.map((child: any) => transformMenuItem(child)) : []
            };
        }

        if (updatedItems.length === 1) {
            const updatedBotData = transformMenuItem(updatedItems[0]);

            setItems(updatedItems);
            setBot(updatedBotData);
            console.log(updatedBotData);
        }
    }


    const handleDragEnd = (event: DragEndEvent) => {
        const {active, over} = event;
        console.log(active, over);
    };

    return (
        <Box
            sx={{
                width: 300,
                paddingTop: "64px",
                borderRight: "1px solid #ccc",
            }}
        >
            <DndContext onDragEnd={handleDragEnd}>
                <div className="tree">
                    <div className="tree__menu">
                        <div className="tree__menu__header">
                            <span>Menu list</span>
                        </div>
                        <div className="tree__menu__tree">
                            <SortableTree
                                items={items}
                                TreeItemComponent={TreeItem}
                                onItemsChanged={handleItemsChanged}
                            />
                        </div>
                    </div>
                </div>
            </DndContext>
            <Button sx={{
                mt: 1,
                width: '100%',
                backgroundColor: 'white',
                color: 'black',
                '&:hover': {backgroundColor: '#f0f0f0'},
            }}
                    onClick={() => {
                        saveChanges()
                    }}
            > Зберегти зміни</Button>
        </Box>
    );
};
