import { Navigate } from "react-router-dom";
import { FC } from "react";

interface RoleBasedRouteProps {
  element: React.ElementType;
  allowedRoles: string[];
}

const RoleBasedRoute: FC<RoleBasedRouteProps> = ({ element: Component, allowedRoles }) => {
  const userRole = window.localStorage.getItem("role");

  if (userRole && allowedRoles.includes(userRole)) {
    return <Component />;
  } else {
    return <Navigate to="/auth" />;
  }
  /*return <Component/>*/
};

export default RoleBasedRoute;
