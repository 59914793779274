import axios from "axios";

export const httpClient = axios.create({
    baseURL: "https://toporivtsi.digitalcity.com.ua/api",
    headers: {
        'Content-Type': 'application/json',
    }
});

httpClient.interceptors.request.use((config) => {
    const token = window.localStorage.getItem("token");

    if (token) config.headers.Authorization = "Bearer " + token;

    return config;
});

export const imageClient = axios.create({
    baseURL: "https://api.uploadthing.com",

})
