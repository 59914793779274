import {BotTree} from "../../home/components/bot-tree";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import {Copyright} from "../../../components/copyright";
import {DeleteDialog} from "../../home/components/delete-dialog";
import * as React from "react";
import {ItemResponseDto} from "../../../dto/item-response.dto";
import {useEffect, useState} from "react";
import toast from "react-hot-toast";
import {httpClient} from "../../../util/axios";
import {ServerResponseDto} from "../../../dto/server-response.dto";
import {TreeItems} from "dnd-kit-sortable-tree";
import {ITreeItem} from "../../../assets/type";
import {SettingsContent} from "../../home/components/settings-component";

const itemResponse: ItemResponseDto = {
    id: 'item1',
    subitem: false,
    name: 'Bot item',
    description: 'Some description',
    order: 1,
    canReply: false,
    imageUrl: "",
    children: [
        {
            id: 'grid-community',
            subitem: true,
            name: 'Bot subitem 1',
            description: 'Subitem description 1',
            order: 2,
            canReply: false,
            imageUrl: "",
            children: [],
        },
        {
            id: 'grid-pro',
            subitem: true,
            name: 'Bot subitem 2',
            description: 'Subitem <b>description</b> 2',
            order: 3,
            canReply: true,
            imageUrl: "", children: [
                {
                    id: 'qwe',
                    subitem: true,
                    name: 'Bot subitem qwe',
                    description: 'Sub-subitem description',
                    order: 4,
                    canReply: true,
                    imageUrl: "", children: [],
                },
            ],
        },
        {
            id: 'grid-premium',
            subitem: true,
            name: 'Bot subitem 3',
            description: 'Subitem description 3',
            order: 5,
            canReply: false,
            imageUrl: "", children: [],
        },
    ],
};


export default function Settings() {

    const contentEditableRef = React.useRef<HTMLDivElement>(null);
    const [name, setName] = React.useState('');
    const [canReply, setCanReply] = React.useState(false);
    const [bot, setBot] = React.useState<ItemResponseDto>(itemResponse);
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [nodeToDelete, setNodeToDelete] = React.useState<ItemResponseDto | null>(null);
    const [selectId, setSelectId] = React.useState('item1');
    const [tree, setTree] = useState<TreeItems<ITreeItem>>([{
        id: "error",
        name: "error",
        level: 1,
        order: 1,
        children: []
    }]);


    useEffect(() => {
        /*const fetchBot = () => {
            let url = "/bot";

            httpClient
                .get <ServerResponseDto<ItemResponseDto>>(url)
                .then((res) => {
                    setIsLoading(false);
                    const data = res.data;

                    if (data.msg) toast.error(data.msg);
                    if (data.data) setBot(data.data);
                });
        };*/

        setTree([createTree(bot, 1)]);
        setName(bot.name);
        if (contentEditableRef.current) {
            contentEditableRef.current.innerHTML = bot.description;
        }
        setCanReply(bot.canReply)
    }, [bot]);

    const applyStyle = (style: string) => {
        document.execCommand(style, false);
    };

    const updateBotItem = (item: ItemResponseDto, id: string, updatedData: Partial<ItemResponseDto>): ItemResponseDto => {
        if (item.id === id) {
            return {...item, ...updatedData};
        }

        if (item.children.length > 0) {
            const updatedChildren = item.children.map((child) =>
                updateBotItem(child, id, updatedData)
            );
            return {...item, children: updatedChildren};
        }

        return item;
    };

    const handleSubmit = () => {
        if (!contentEditableRef.current) {
            return;
        }

        const updatedData = {
            name: name,
            description: contentEditableRef.current.innerHTML,
            canReply: canReply,
        };

        try {
            console.log(updatedData)
            const updatedBot = updateBotItem(bot, selectId, updatedData);
            setBot(updatedBot);
            localStorage.setItem('bot', JSON.stringify(updatedBot));

            toast.success('Дані успішно оновлено');
        } catch (error) {
            toast.error('Помилка при оновленні даних');
            console.error(error);
        }
    };


    function createTree(item: ItemResponseDto, level: number): ITreeItem {
        return {
            id: item.id,
            name: item.name,
            level: level,
            order: item.order,
            children: item.children.length > 0
                ? item.children.map((child) => createTree(child, level + 1))
                : [],
        };
    }


    const handleTreeItemClick = (itemId: string) => {
        const findItem = (item: ItemResponseDto): ItemResponseDto | null => {
            if (item.id === itemId) return item;
            for (const child of item.children) {
                const found = findItem(child);
                if (found) return found;
            }
            return null;
        };

        const selectedItem = findItem(bot);
        if (selectedItem) {
            setSelectId(itemId);
            setName(selectedItem.name);
            if (contentEditableRef.current) {
                contentEditableRef.current.innerHTML = selectedItem.description;
            }
            setCanReply(selectedItem.canReply);
        }
    };

    const handleAddChild = async () => {
        const newChild = {
            name: 'New Child',
            description: 'Description of the new child',
            parentId: bot.id,
        };

        try {
            const response = await httpClient.post<ServerResponseDto<ItemResponseDto>>(`/bot/${bot.id}/children`, newChild);
            if (response.data.msg) {
                toast.error(response.data.msg);
            } else {
                toast.success('Дочірній елемент успішно додано');
                setBot(response.data.data);
                setTree([createTree(response.data.data, 1)]);
            }
        } catch (error) {
            toast.error('Помилка при додаванні дочірнього елемента');
            console.error(error);
        }
    };

    const handleDeleteConfirm = async () => {
        if (!nodeToDelete) return;

        try {
            const response = await httpClient.delete<ServerResponseDto<ItemResponseDto>>(`/bot/${nodeToDelete.id}`);
            if (response.data.msg) {
                toast.error(response.data.msg);
            } else {
                toast.success('Елемент успішно видалено');
                setBot(response.data.data);
                setTree([createTree(response.data.data, 1)]);
            }
        } catch (error) {
            toast.error('Помилка при видаленні елемента');
            console.error(error);
        } finally {
            setOpenDeleteDialog(false);
            setNodeToDelete(null);
        }
    };

    const handleDeleteCancel = () => {
        setOpenDeleteDialog(false);
        setNodeToDelete(null);
    };

    const handleDeleteClick = (node: ItemResponseDto) => {
        setNodeToDelete(node);
        setOpenDeleteDialog(true);
    };

    return (
        <Box sx={{display: 'flex', flexDirection: 'row', width: '100%'}}>
            <BotTree items={tree} setItems={setTree} selectClick={handleTreeItemClick} bot={bot} setBot={setBot}/>
            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : theme.palette.grey[900],
                    flexGrow: 1,
                    height: 'calc(100vh - 87px)',
                    overflow: 'auto',
                    marginTop: '87px',
                }}
            >
                <Toolbar/>
                <Container maxWidth="lg" sx={{mt: 4, mb: 4,}}>
                    <Grid container spacing={3}>
                        <SettingsContent
                            name={name}
                            setName={setName}
                            canReply={canReply}
                            setCanReply={setCanReply}
                            contentEditableRef={contentEditableRef}
                            handleSubmit={handleSubmit}
                            handleAddChild={handleAddChild}
                            handleDeleteClick={handleDeleteClick}
                            applyStyle={applyStyle}
                        />
                    </Grid>
                    <Copyright sx={{pt: 4}}/>
                </Container>
                <DeleteDialog
                    handleDeleteCancel={handleDeleteCancel}
                    handleDeleteConfirm={handleDeleteConfirm}
                    openDeleteDialog={openDeleteDialog}
                />
            </Box>
        </Box>
    );
}