import React, {useEffect, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {initialData} from '../../../assets/constant';
import {Box, Button, Typography, TextField, Paper, Select, MenuItem} from '@mui/material';
import {TicketText} from "../components/ticket-text";
import {Message} from "../components/message";
import {httpClient} from "../../../util/axios";
import {ServerResponseDto} from "../../../dto/server-response.dto";
import toast from "react-hot-toast";
import {ReplyResponseDto, Status, Type} from "../../../dto/reply-response.dto";
import {SelectChangeEvent} from '@mui/material';
import {AnswerDto} from "../../../dto/answer-dto";
import {Copyright} from "../../../components/copyright";
import {CitizenResponseDto} from "../../../dto/citizen-response.dto";

export const MessagePage: React.FC = () => {
    const {id} = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [ticket, setTicket] = useState<ReplyResponseDto>(initialData[0]);
    const [selectType, setSelectType] = useState(ticket.appeal_type);
    const [message, setMessage] = useState('');
    const [contact, setContact] = useState('');
    const [chat, setChat] = useState<AnswerDto>();

    useEffect(() => {
        const fetchTicket = () => {
            let ticketUrl = `/appeal/${id}`;
            let chatUrl = `/answer/by/${id}`;
            let citizenUrl = `/citizen/${id}`

            httpClient
                .get<ServerResponseDto<ReplyResponseDto>>(ticketUrl)
                .then((res) => {
                    setIsLoading(false);
                    const data = res.data;

                    if (data.msg) toast.error(data.msg);
                    if (data.data) setTicket(data.data);
                })
                .catch((error) => {
                    console.error('Помилка при завантаженні звернення:', error);
                });

            httpClient
                .get<ServerResponseDto<AnswerDto>>(chatUrl)
                .then((res) => {
                    setIsLoading(false);
                    const data = res.data;

                    if (data.msg) toast.error(data.msg);
                    if (data.data) setChat(data.data);
                })
                .catch((error) => {
                    console.error('Помилка при завантаженні відповіді:', error);
                });

            httpClient
                .get<ServerResponseDto<CitizenResponseDto>>(citizenUrl)
                .then((res) => {
                    setIsLoading(false);
                    const data = res.data;

                    if (data.msg) toast.error(data.msg);
                    if (data.data) setContact(data.data.phone);
                })
                .catch((error) => {
                    console.error('Помилка при завантаженні відповіді:', error);
                });
        };

        fetchTicket();

    }, [id]);

    const sendMessage = async () => {
        let url = `/answer/create`;

        httpClient
            .post<ServerResponseDto<AnswerDto>>(url, {appeal_id: ticket.id, message: message, date: ""})
            .then((res) => {
                const data = res.data;

                if (data.msg) toast.error(data.msg);
                if (data.data) setChat(data.data);
                close();
            })
            .catch((error) => {
                toast.error('Помилка при відправці повідомлення');
                console.error('Помилка при відправці повідомлення:', error);
            });
    }

    const changeTicketType = async (event: SelectChangeEvent<string>) => {
        const selectedType = event.target.value as Type;
        setSelectType(selectedType);
        let url = `/appeal/${id}`;

        httpClient
            .put<ServerResponseDto<ReplyResponseDto>>(url, {...ticket, appeal_type: selectedType})
            .then((res) => {
                const data = res.data;

                if (data.msg) toast.error(data.msg);
                if (data.data) setTicket(data.data);
            })
            .catch((error) => {
                console.error('Помилка при зміні типу звернення:', error);
            });
    };

    const open = async () => {
        let url = `/appeal/${id}`;

        httpClient
            .put<ServerResponseDto<ReplyResponseDto>>(url, {...ticket, status: Status.inWork})
            .then((res) => {
                const data = res.data;

                if (data.msg) toast.error(data.msg);
                if (data.data) setTicket(data.data);
            })
            .catch((error) => {
                toast.error('Помилка при відправці повідомлення');
                console.error('Помилка при відправці повідомлення:', error);
            });
    }
    const startWork = async () => {
        const updatedTicket = {...ticket, status: Status.inWork};
        let url = `/appeal/${id}`;

        httpClient
            .put<ServerResponseDto<ReplyResponseDto>>(url, {...ticket, status: Status.inWork})
            .then((res) => {
                const data = res.data;

                if (data.msg) toast.error(data.msg);
                if (data.data) setTicket(data.data);
            })
            .catch((error) => {
                toast.error('Помилка при відправці повідомлення');
                console.error('Помилка при відправці повідомлення:', error);
            });
    }

    const close = async () => {
        const updatedTicket = {...ticket, status: Status.closed};
        let url = `/appeal/${id}`;

        httpClient
            .put<ServerResponseDto<ReplyResponseDto>>(url, {...ticket, status: Status.closed})
            .then((res) => {
                const data = res.data;

                if (data.msg) toast.error(data.msg);
                if (data.data) setTicket(data.data);
            })
            .catch((error) => {
                toast.error('Помилка при відправці повідомлення');
                console.error('Помилка при відправці повідомлення:', error);
            });
    }

    if (!ticket) {
        return (
            <Box sx={{padding: '24px'}}>
                <Typography variant="h6">Запис не знайдено.</Typography>
                <Button variant="contained" onClick={() => navigate(-1)} sx={{mt: 2}}>
                    Назад
                </Button>
            </Box>
        );
    }

    return (
        <Box sx={{padding: '24px', mt: "87px"}}>
            {/* Main ticket details */}
            <Paper sx={{
                padding: 4, marginBottom: '16px', borderRadius: '25px', backgroundColor: '#f0f0f0',
            }}>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "16px",
                }}>
                    <Box sx={{
                        backgroundColor: '#3d553d',
                        borderRadius: '25px',
                        display: 'inline-block',
                        padding: '8px 16px',
                        maxWidth: 'fit-content',
                    }}>
                        <Typography
                            variant="h6"
                            gutterBottom
                            sx={{
                                color: '#ffffff',
                                textAlign: 'center',
                                margin: 0,
                            }}
                        >
                            Звернення №{ticket.id}
                        </Typography>
                    </Box>
                    <Box sx={{maxWidth: 'fit-content'}}>
                        <TicketText text={ticket.message}/>
                    </Box>
                </Box>

                <Box
                    sx={{
                        mt: 2,
                        mb: 2,
                        border: 'none',
                        borderTop: '5px solid #3d553d',
                        width: '100%',
                        borderRadius: '25px',
                    }}
                />
                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <Box>
                        <Typography variant="body2">
                            <strong>Контакт:</strong> {contact ? contact : "контакт відсутній"}
                        </Typography>
                        <Typography variant="body2">
                            <strong>Статус:</strong> {ticket.status == Status.waiting ? "Очікує відповідь" : ticket.status == Status.inWork ? "В роботі" : "Закрито"}
                        </Typography>
                        <Typography variant="body2">
                            <strong>Створено:</strong> {ticket.date}
                        </Typography>
                    </Box>
                    <Select
                        value={selectType}
                        onChange={changeTicketType}
                        label="Оберіть тип звернення"
                    >
                        <MenuItem value={Type.others}>Інше звернення</MenuItem>
                        <MenuItem value={Type.complaint}>Пропозиції/Скарга</MenuItem>
                    </Select>
                </Box>
                {/* Reply input */}
                <Box sx={{mt: "24px", mb: '24px'}}>
                    {ticket.status === Status.waiting ? (
                        <Button variant="contained" color="success" onClick={startWork}>
                            Взяти в роботу
                        </Button>
                    ) : ticket.status === Status.inWork ? (
                        <>
                            <TextField
                                label="Введіть відповідь"
                                variant="outlined"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                fullWidth
                                multiline
                                rows={3}
                                sx={{marginBottom: '16px', backgroundColor: 'white', border: 'none'}}
                            />
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 2,
                                    mt: 2,
                                    width: '100%',
                                    maxWidth: '300px',
                                }}
                            >
                                <Button variant="contained" color="success" onClick={sendMessage} fullWidth>
                                    Відправити
                                </Button>
                                <Button variant="contained" color="success" onClick={close} fullWidth>
                                    Закрити
                                </Button>
                            </Box>


                        </>
                    ) : null}

                </Box>
            </Paper>

            {/* Message history */
            }
            <Paper sx={{
                p: 4,
                mt: '24px',
                display: 'flex',
                flexDirection: 'column',
                borderRadius: '25px',
                backgroundColor: '#f0f0f0',
            }}>
                <Box>
                    <Box sx={{
                        backgroundColor: '#3d553d',
                        borderRadius: '25px',
                        display: 'inline-block',
                        padding: '8px 16px',
                        marginBottom: "24px",
                    }}>
                        <Typography
                            variant="h6"
                            gutterBottom
                            sx={{
                                color: '#ffffff',
                                textAlign: 'center',
                                margin: 0,
                            }}
                        >
                            Історія спілкування
                        </Typography>
                    </Box>

                    {/* {
                        chat.map((message, index) => (
                            <Message key={index} text={message.message} date={message.date} isAdmin={isAdmin}/>
                        ))
                    }*/}
                    <Message key={"reply"} text={ticket.message} date={ticket.date} isAdmin={false}/>
                    {chat ?
                        <Message key={"answer"} text={chat.message} date={chat.date} isAdmin={true}/>
                        : null
                    }
                </Box>
            </Paper>
            <Copyright sx={{pt: 4}}/>

        </Box>
    );
};
