import React from "react";
import {Typography} from "@mui/material";

interface TicketTextProps {
    text: string;
}

export const TicketText: React.FC<TicketTextProps> = ({
                                                          text
                                                      }) => {
    return (
        <Typography
            variant="body1"
            gutterBottom
            sx={{
                position: "relative",
                background: 'linear-gradient(to right, rgba(144, 238, 144, 0.3), rgba(173, 216, 230, 0.3))',
                paddingRight: '16px',
                paddingLeft: '16px',
                display: "inline-block",
                p: "10px",
                borderRadius: "5px",
                "&::before": {
                    content: '""',
                    position: "absolute",
                    paddingLeft: "5px",
                    left: 0,
                    top: 0,
                    bottom: 0,
                    width: "4px",
                    backgroundColor: "#3d553d",
                    borderRadius: "2px",
                }
            }}
        >
            {text}
        </Typography>
    )
}