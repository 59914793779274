import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import LogoutIcon from "@mui/icons-material/Logout";
import * as React from "react";
import {AppBar} from "@mui/material";
import {useAuth} from "../../sign-in/providers/auth-provider";
import Box from "@mui/material/Box";
import logo from '../../../assets/logo.png';
import {Button} from "@mui/material";
import {NavLink} from "react-router-dom";
import {Roles} from "../../../dto/user-response.dto";  // Імпорт NavLink

export default function AppBarComponent() {
    const {logout} = useAuth();

    return (
        <AppBar
            position="fixed"
            sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
                backgroundColor: "#3d553d",
                height: "87px",
                display: "flex",
                flexDirection: "row",
                justifyContent: 'space-between',
            }}
        >
            <Toolbar
                sx={{
                    pr: "24px",
                    minHeight: "87px",
                }}
            >
                <img src={logo} alt="Logo" style={{height: "40px"}}/>
            </Toolbar>

            {/* Navigation Buttons */}
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                {window.localStorage.getItem("role") === Roles.superAdmin ?
                    <NavLink
                    to="/settings"
                    style={({isActive}) => ({
                        textDecoration: 'none',
                    })}
                >
                    {({isActive}) => (
                        <Button
                            sx={{
                                color: isActive ? "#3d553d" : "white",
                                backgroundColor: isActive ? "white" : "transparent",
                                textTransform: 'none',
                                borderRadius: "50px",
                                mr: "16px",
                                "&:hover": {
                                    backgroundColor: isActive ? "white" : "#3d553d",
                                },
                                "&:focus": {
                                    backgroundColor: isActive ? "white" : "#3d553d",
                                },
                                "&:active": {
                                    backgroundColor: isActive ? "white" : "#3d553d",
                                },
                            }}
                        >
                            Налаштування
                        </Button>
                    )}
                </NavLink> : null}

                <NavLink
                    to="/"
                    style={({isActive}) => ({
                        textDecoration: 'none',
                    })}
                >
                    {({isActive}) => (
                        <Button
                            sx={{
                                color: isActive ? "#3d553d" : "white",
                                backgroundColor: isActive ? "white" : "transparent",
                                textTransform: 'none',
                                mr: "16px",
                                borderRadius: "50px",
                                "&:hover": {
                                    backgroundColor: isActive ? "white" : "#3d553d",
                                },
                                "&:focus": {
                                    backgroundColor: isActive ? "white" : "#3d553d",
                                },
                                "&:active": {
                                    backgroundColor: isActive ? "white" : "#3d553d",
                                },
                            }}
                        >
                            Звернення громадян
                        </Button>
                    )}
                </NavLink>
            </Box>

            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <IconButton color="inherit" onClick={logout}>
                    <LogoutIcon/>
                </IconButton>
            </Box>
        </AppBar>
    );
}
